<template>
    <div class="zch-home-body">
        <Navigation />
        <div class="zch-home-content">
            <div class="zch-home-content-hero">
                <div class="zch-home-content-heading">
                    <img src="../assets/logo.svg" alt="" />
                    <h1 id="HomeIntroHeadline">
                        ZAKCHAT is a chatty robot who works out your Zakat,<br />
                        so you always pay it accurately.
                    </h1>
                    <p>ZAKCHAT (TM) uses crowdfunding to help fundraise for charity</p>
                </div>
                <router-link :to="{ name: 'Chat' }" v-slot="{ href, navigate }" v-if="isPublic">
                    <a :href="href" @click="navigate" class="zch-home-content-cta"> CHAT NOW </a>
                </router-link>
                <a class="zch-home-content-cta disabled" v-else> COMING SOON </a>
                <div class="zch-home-content-flow">
                    <div class="zch-home-content-flow-item">
                        <img src="../assets/flow-chat.svg" alt="" />
                        <div class="zch-home-content-flow-item-text">
                            <h5>Chat</h5>
                            <p>Chat to ZAKCHAT, your friendly personal assistant, to work out your Zakat.</p>
                        </div>
                    </div>
                    <div class="zch-home-content-flow-arrow">
                        <img src="../assets/arrow-right.svg" alt="" />
                    </div>
                    <div class="zch-home-content-flow-item">
                        <img src="../assets/flow-preview.svg" alt="" />
                        <div class="zch-home-content-flow-item-text">
                            <h5>Review</h5>
                            <p>Check and understand how your Zakat is worked out.</p>
                        </div>
                    </div>
                    <div class="zch-home-content-flow-arrow">
                        <img src="../assets/arrow-right.svg" alt="" />
                    </div>
                    <div class="zch-home-content-flow-item">
                        <img src="../assets/flow-pay.svg" alt="" />
                        <div class="zch-home-content-flow-item-text">
                            <h5>Donate</h5>
                            <p>Choose a Zakat eligible charity, and donate online.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="zch-home-content-info">
                <img
                    class="desktop"
                    src="../assets/home/goal-illustration.png"
                    alt="The best way to calculate your Zakat accurately"
                />
                <img
                    class="phone"
                    src="../assets/home/goal-illustration-phone.png"
                    alt="The best way to calculate your Zakat accurately"
                />
                <div class="zch-home-content-info-text">
                    <h2>The best way to calculate your Zakat accurately</h2>
                    <p>
                        Unlike unreliable Zakat calculators, ZAKCHAT asks all the right questions to accurately
                        calculate your Zakat.
                    </p>
                </div>
            </div>
            <div class="zch-home-content-info">
                <img
                    class="phone"
                    src="../assets/home/verified-illustration-phone.png"
                    alt="The best way to calculate your Zakat accurately"
                />
                <div class="zch-home-content-info-text">
                    <h2>Verified by qualified Scholars</h2>
                    <p>
                        Verified by qualified Scholars, you can have confidence and trust you’re working out your Zakat
                        properly.
                    </p>
                </div>
                <img
                    class="desktop"
                    src="../assets/home/verified-illustration.png"
                    alt="The best way to calculate your Zakat accurately"
                />
            </div>
            <div class="zch-home-content-info">
                <img
                    class="desktop"
                    src="../assets/home/everyone-illustration.png"
                    alt="The best way to calculate your Zakat accurately"
                />
                <img
                    class="phone"
                    src="../assets/home/everyone-illustration-phone.png"
                    alt="The best way to calculate your Zakat accurately"
                />
                <div class="zch-home-content-info-text">
                    <h2>Zakat calculation for everyone</h2>
                    <p>
                        Even if you own businesses, properties or shares, ZAKCHAT is ready to use no matter your
                        financial circumstances.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navigation from "@/layouts/Navigation";
import { isChatPublic } from "@/service/chatpublic.js";

export default {
    components: {
        Navigation,
    },
    data() {
        return {
            isPublic: null,
        };
    },
    mounted() {
        this.isPublic = isChatPublic();
    },
};
</script>

<style lang="scss" scoped>
.zch-home {
    &-body {
        background-image: radial-gradient(circle at 50% 50%, #fbfffe, #f6fffc 151%);
        width: 100%;
        height: 100%;
        flex-direction: column;
        padding: 24px;
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-heading {
            display: inherit;
            justify-content: inherit;
            flex-direction: inherit;
            align-items: center;

            & > img {
                width: 240px;
                height: 240px;
            }

            & > h1 {
                font-family: Muli, sans-serif;
                font-size: 40px;
                font-weight: 800;
                text-align: center;
                color: #131313;
                line-height: 1;
                margin: 0;
                @media screen and (max-width: 1920px) {
                    font-size: 32px;
                    margin-top: 20px;
                }
                @media screen and (max-width: 575px) {
                    font-size: 24px;
                    margin-top: 20px;
                }
            }
        }

        &-cta {
            width: max-content;
            font-family: Muli, sans-serif;
            border-radius: 12px;
            background-color: #ff6666;
            font-size: 19.8px;
            font-weight: 800;
            line-height: 1.52;
            color: #ffffff;
            margin: 35px 0 25px 0;
            padding: 23px 50px;
            text-decoration: none;
            transition: 500ms;
            text-transform: uppercase;

            &:hover {
                opacity: 0.7;
                transition: 500ms;
            }

            &.disabled {
                cursor: default;
                &:hover {
                    opacity: 1;
                }
            }
        }

        &-hero {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &-info {
            display: flex;
            justify-content: space-between;
            margin-top: 80px;

            @media screen and (max-width: 992px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            & > .phone {
                @media screen and (min-width: 992px) {
                    display: none;
                }
            }

            & > .desktop {
                @media screen and (max-width: 992px) {
                    display: none;
                }
            }

            &-text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 62%;
                color: $color-cod-gray;
                font-family: Muli, sans-serif;

                @media screen and (max-width: 992px) {
                    width: 100%;
                }

                & > h2 {
                    font-size: 24px;
                    font-weight: 800;
                    color: $color-cod-gray;
                    margin-bottom: 17px;
                }

                & > p {
                    color: $color-cod-gray;
                    font-size: 17px;
                    font-weight: 500;
                    line-height: 1.4;
                    margin: 0;
                    width: 100%;
                }
            }
        }
        &-flow {
            display: flex;
            margin: 36px 17.5% 0 17.5%;
            justify-content: space-between;
            width: 75%;
            min-height: max-content;

            @media screen and (min-width: 1440px) {
                width: 50%;
            }

            @media screen and (max-width: 992px) {
                flex-direction: column;
                margin: 36px 7px 0 7px;
                padding-bottom: 50px;
            }

            &-item {
                max-width: 210px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-family: Muli, sans-serif;
                height: max-content;

                @media screen and (max-width: 992px) {
                    flex-direction: row;
                    max-width: unset;
                }

                &:last-child {
                    @media screen and (max-width: 992px) {
                        margin-bottom: 50px;
                    }
                }

                &-text {
                    & > h5 {
                        font-family: Muli, sans-serif;
                        margin-top: 0;
                        font-size: 24px;
                        color: #131313;
                        @media screen and (max-width: 992px) {
                            margin-bottom: 0;
                            text-align: initial;
                        }
                    }

                    & > p {
                        font-size: 16px;
                        text-align: center;
                        color: #131313;
                        max-width: 200px;
                        margin-top: -15%;
                        @media screen and (max-width: 992px) {
                            text-align: left;
                            margin-top: 0;
                        }
                    }

                    @media screen and (max-width: 992px) {
                        margin-left: 30px;
                    }
                }

                & > img {
                    max-width: 140px;
                    max-height: 105px;
                    margin-bottom: 35px;
                    @media screen and (max-width: 992px) {
                        margin-bottom: 0;
                    }
                }
            }

            &-arrow {
                display: flex;
                align-items: center;
                margin-top: -20%;
                @media screen and (max-width: 992px) {
                    transform: rotate(90deg);
                    width: max-content;
                    align-self: center;
                    margin: 25px 0;
                }
                @media screen and (min-width: 1920px) {
                    margin-top: -15%;
                }
            }
        }
    }
}
</style>
